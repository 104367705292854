import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of, tap } from "rxjs";
import { env } from "../../../environment/environment";
import { Login } from "../interfaces/Login";
import { ApiResponse } from "../interfaces/ApiResponse";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private apiUrl = env.API_URL +'/users';
  private loggedInEmail = new BehaviorSubject<string | null>(null);
  private userTypeSubject = new BehaviorSubject<string | null>(null);

  constructor(private router: Router, private http: HttpClient) {
    const email = localStorage.getItem("loggedInEmail");
    this.currentUserId = localStorage.getItem("currentUserId") || undefined;
    if (email) {
      this.loggedInEmail.next(email);
    }
  }

  login(body: Login): Observable<any> {
    return this.loginUser(body).pipe(
      tap((response) => {
        if (response && response.user?.email && response.user?.roles) {
          localStorage.setItem("loggedInEmail", response.user.email);
          localStorage.setItem("currentUserId", response.user.id);
          localStorage.setItem("access_token", response.token);
          localStorage.setItem("authToken", response.token);
          
          this.loggedInEmail.next(response.user.email);
          this.updateUserType(response.user.roles);
        }
      })
    );
  }

  logout() {
    localStorage.clear();
    this.router.navigate(["/home"]);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  getUserType(): Observable<string | null> {
    return this.userTypeSubject.asObservable();
  }

  private loginUser(
    body: Login
  ): Observable<any> {
    return this.http.post<ApiResponse<Login>>(`${this.apiUrl}/login`, body);
  }

  private updateUserType(roles: string[] | null) {
    const storedUserType = localStorage.getItem('userType');
    if (storedUserType) {
      this.userTypeSubject.next(storedUserType);
    } else if (roles && roles.includes("counselor")) {
      this.userTypeSubject.next("counselor");
      localStorage.setItem('userType', 'counselor');
    } else if (roles && roles.includes("marveler")) {
      this.userTypeSubject.next("marveler");
      localStorage.setItem('userType', 'marveler');
    } else {
      this.userTypeSubject.next(null);
      localStorage.removeItem('userType');
    }
  }  

  private currentUserId: string | undefined;

  getCurrentUserId(): Observable<string> {
    if (this.currentUserId) {
      return of(this.currentUserId);
    } else {
      const storedId = localStorage.getItem("currentUserId");
      if (storedId) {
        this.currentUserId = storedId;
        return of(this.currentUserId);
      } else {
        throw new Error("User ID is not set");
      }
    }
  }

  verifyAccount(token: string) {
    return this.http.get<ApiResponse<any>>(`${this.apiUrl}/verify/${token}`);
  }

  forgetPassword(email: string) {
    return this.http.post<ApiResponse<any>>(`${this.apiUrl}/forgot-password`, { email });
  }

  resetPassword(body: any) {
    return this.http.post<ApiResponse<any>>(`${this.apiUrl}/reset-password`, body);
  }

  getAccessToken() {
    return localStorage.getItem("access_token");
  }
}
